import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    meta: {
      title: 'Inicio',
    },
    path: '/',
    name: 'home',
    component: () => import('../views/home/Home.vue')
  },
  // Pedidos
  {
    meta: {
      title: 'Pedidos'
    },
    path: '/orders',
    name: 'orders',
    component: () => import('../views/orders/Orders.vue')
  },
  {
    meta: {
      title: 'Pedidos Pendientes'
    },
    path: '/orders/pending',
    name: 'orders.pending',
    component: () => import('../views/orders/Pending.vue')
  },
  {
    meta: {
      title: 'Pedidos Históricos'
    },
    path: '/orders/history',
    name: 'orders.history',
    component: () => import('../views/orders/History.vue')
  },
  {
    meta: {
      title: 'Pedidos Históricos'
    },
    path: '/orders/history/:month',
    name: 'orders.history.month',
    component: () => import('../views/orders/HistoryMonth.vue'),
    props: true
  },
  {
    meta: {
      title: 'Ingresar Pedido'
    },
    path: '/orders/create',
    name: 'orders.create',
    component: () => import('../views/orders/OrdersCreate.vue'),
  },
  {
    meta: {
      title: 'Ver Pedido'
    },
    path: '/orders/:id',
    name: 'orders.view',
    component: () => import('../views/orders/OrdersView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Pedido'
    },
    path: '/orders/:id/edit',
    name: 'orders.edit',
    component: () => import('../views/orders/OrdersEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Preparar Pedido'
    },
    path: '/orders/:id/prepare',
    name: 'orders.prepare',
    component: () => import('../views/orders/OrdersPrepare.vue'),
    props: true
  },
  {
    meta: {
      title: 'Entregar Pedido'
    },
    path: '/orders/:id/handover',
    name: 'orders.handover',
    component: () => import('../views/orders/OrdersHandover.vue'),
    props: true
  },
  {
    meta: {
      title: 'Vista Cliente'
    },
    path: '/orders/:id/share',
    name: 'orders.share',
    component: () => import('../views/orders/ShareView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Vista Factura'
    },
    path: '/orders/:id/invoice',
    name: 'orders.invoice',
    component: () => import('../views/orders/InvoiceView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Peticiones'
    },
    path: '/requests',
    name: 'requests',
    component: () => import('../views/orders/Requests.vue')
  },
  {
    meta: {
      title: 'Ver Petición'
    },
    path: '/requests/:id',
    name: 'requests.view',
    component: () => import('../views/orders/RequestsView.vue'),
    props: true
  },
  // Clientes
  {
    meta: {
      title: 'Clientes'
    },
    path: '/customers',
    name: 'customers',
    component: () => import('../views/customers/Customers.vue')
  },
  {
    meta: {
      title: 'Ingresar Cliente'
    },
    path: '/customers/create',
    name: 'customers.create',
    component: () => import('../views/customers/CustomersCreate.vue')
  },
  {
    meta: {
      title: 'Editar Cliente'
    },
    path: '/customers/:id/edit',
    name: 'customers.edit',
    component: () => import('../views/customers/CustomersEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Ver Cliente'
    },
    path: '/customers/:id',
    name: 'customers.view',
    component: () => import('../views/customers/CustomersView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Agregar Dirección'
    },
    path: '/customers/:id/add_address',
    name: 'customers.add_address',
    component: () => import('../views/customers/CustomersAddAddress.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Dirección'
    },
    path: '/customers/addresses/:id/edit',
    name: 'customers.addresses.edit',
    component: () => import('../views/customers/CustomersEditAddress.vue'),
    props: true
  },
  {
    meta: {
      title: 'Restaurantes'
    },
    path: '/companies',
    name: 'companies',
    component: () => import('../views/customers/Companies.vue')
  },
  {
    meta: {
      title: 'Ingresar Restaurante'
    },
    path: '/companies/create',
    name: 'companies.create',
    component: () => import('../views/customers/CompaniesCreate.vue')
  },
  {
    meta: {
      title: 'Ver Restaurante'
    },
    path: '/companies/:id',
    name: 'companies.view',
    component: () => import('../views/customers/CompaniesView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Restaurante'
    },
    path: '/companies/:id/edit',
    name: 'companies.edit',
    component: () => import('../views/customers/CompaniesEdit.vue'),
    props: true
  },
  // Inventarios
  {
    meta: {
      title: 'Inventarios'
    },
    path: '/inventories',
    name: 'inventories',
    component: () => import('../views/inventories/Inventories.vue')
  },
  {
    meta: {
      title: 'Crear Inventario'
    },
    path: '/inventories/create',
    name: 'inventories.create',
    component: () => import('../views/inventories/InventoriesCreate.vue')
  },
  {
    meta: {
      title: 'Ver Inventario'
    },
    path: '/inventories/:id',
    name: 'inventories.view',
    component: () => import('../views/inventories/InventoriesView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Inventario'
    },
    path: '/inventories/:id/edit',
    name: 'inventories.edit',
    component: () => import('../views/inventories/InventoriesEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Inventarios'
    },
    path: '/inventories/summary',
    name: 'inventories.summary',
    component: () => import('../views/inventories/SummaryIndex.vue')
  },
  {
    meta: {
      title: 'Inventarios'
    },
    path: '/inventories/summary/:date',
    name: 'inventories.date',
    component: () => import('../views/inventories/SummaryView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Historial Inventarios'
    },
    path: '/inventories/history',
    name: 'inventories.history',
    component: () => import('../views/inventories/History.vue')
  },
  {
    meta: {
      title: 'Inventarios Históricos'
    },
    path: '/inventories/history/:month',
    name: 'inventories.history.month',
    component: () => import('../views/inventories/HistoryMonth.vue'),
    props: true
  },
  {
    meta: {
      title: 'Límites de Stock'
    },
    path: '/levels',
    name: 'levels',
    component: () => import('../views/inventories/Levels.vue')
  },
  {
    meta: {
      title: 'Crear Límite'
    },
    path: '/levels/create',
    name: 'levels.create',
    component: () => import('../views/inventories/LevelsCreate.vue')
  },
  {
    meta: {
      title: 'Editar Límite'
    },
    path: '/levels/:id/edit',
    name: 'levels.edit',
    component: () => import('../views/inventories/LevelsEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Items'
    },
    path: '/items',
    name: 'items',
    component: () => import('../views/inventories/Items.vue')
  },
  {
    meta: {
      title: 'Crear Item'
    },
    path: '/items/create',
    name: 'items.create',
    component: () => import('../views/inventories/ItemsCreate.vue')
  },
  {
    meta: {
      title: 'Editar Item'
    },
    path: '/items/:id/edit',
    name: 'items.edit',
    component: () => import('../views/inventories/ItemsEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Asignar Item'
    },
    path: '/items/:id/assign',
    name: 'items.assign',
    component: () => import('../views/inventories/ItemsAssign.vue'),
    props: true
  },
  // Transacciones
  {
    meta: {
      title: 'Transacciones'
    },
    path: '/transactions',
    name: 'transactions',
    component: () => import('../views/transactions/Transactions.vue')
  },
  {
    meta: {
      title: 'Ingresar Transacción'
    },
    path: '/transactions/create',
    name: 'transactions.create',
    component: () => import('../views/transactions/TransactionsCreate.vue')
  },
  {
    meta: {
      title: 'Editar Transacción'
    },
    path: '/transactions/:id',
    name: 'transactions.edit',
    component: () => import('../views/transactions/TransactionsEdit.vue'),
    props: true
  },
  // Analítica
  {
    meta: {
      title: 'Analítica'
    },
    path: '/analytics',
    name: 'analytics',
    component: () => import('../views/analytics/Analytics.vue')
  },
  {
    meta: {
      title: 'Analítica'
    },
    path: '/analytics/daily/:date',
    name: 'analytics.daily',
    component: () => import('../views/analytics/Daily.vue'),
    props: true
  },
  {
    meta: {
      title: 'Analítica'
    },
    path: '/analytics/monthly/:date',
    name: 'analytics.monthly',
    component: () => import('../views/analytics/Monthly.vue'),
    props: true
  },
  {
    meta: {
      title: 'Reportes'
    },
    path: '/analytics/reports',
    name: 'analytics.reports',
    component: () => import('../views/analytics/Reports.vue'),
    props: true
  },
  // Producción
  {
    meta: {
      title: 'Producción'
    },
    path: '/production',
    name: 'production',
    component: () => import('../views/production/Production.vue')
  },
  {
    meta: {
      title: 'Historial Producción'
    },
    path: '/production/history',
    name: 'production.history',
    component: () => import('../views/production/History.vue')
  },
  {
    meta: {
      title: 'Instancias Históricas'
    },
    path: '/production/history/:month',
    name: 'production.history.month',
    component: () => import('../views/production/HistoryMonth.vue'),
    props: true
  },
  {
    meta: {
      title: 'Flujos'
    },
    path: '/flows',
    name: 'flows',
    component: () => import('../views/production/Flows.vue')
  },
  {
    meta: {
      title: 'Crear Flujo'
    },
    path: '/flows/create',
    name: 'flows.create',
    component: () => import('../views/production/FlowsCreate.vue')
  },
  {
    meta: {
      title: 'Ver Flujo'
    },
    path: '/flows/:id',
    name: 'flows.view',
    component: () => import('../views/production/FlowsView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Flujo'
    },
    path: '/flows/:id/edit',
    name: 'flows.edit',
    component: () => import('../views/production/FlowsEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Crear Actividad'
    },
    path: '/flows/:id/activities/create',
    name: 'activities.create',
    component: () => import('../views/production/ActivitiesCreate.vue'),
    props: true
  },
  {
    meta: {
      title: 'Ver Actividad'
    },
    path: '/activities/:id',
    name: 'activities.view',
    component: () => import('../views/production/ActivitiesView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Ver Actividad'
    },
    path: '/activities/:id/edit',
    name: 'activities.edit',
    component: () => import('../views/production/ActivitiesEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Crear Instancia'
    },
    path: '/instances/create',
    name: 'instances.create',
    component: () => import('../views/production/InstancesCreate.vue')
  },
  {
    meta: {
      title: 'Ver Instancia'
    },
    path: '/instances/:id',
    name: 'instances.view',
    component: () => import('../views/production/InstancesView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Instancia'
    },
    path: '/instances/:id/edit',
    name: 'instances.edit',
    component: () => import('../views/production/InstancesEdit.vue'),
    props: true
  },
  // Productos
  {
    meta: {
      title: 'Productos'
    },
    path: '/products',
    name: 'products',
    component: () => import('../views/products/Products.vue')
  },
  {
    meta: {
      title: 'Crear Producto'
    },
    path: '/products/create',
    name: 'products.create',
    component: () => import('../views/products/ProductsCreate.vue')
  },
  {
    meta: {
      title: 'Editar Producto'
    },
    path: '/products/:id/edit',
    name: 'products.edit',
    component: () => import('@/views/products/ProductsEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Precios'
    },
    path: '/products/:id/prices',
    name: 'products.prices',
    component: () => import('@/views/products/ProductsPrices.vue'),
    props: true
  },
  // Categorías
  {
    meta: {
      title: 'Categorías'
    },
    path: '/categories',
    name: 'categories',
    component: () => import('../views/categories/Categories.vue')
  },
  {
    meta: {
      title: 'Crear Categoría'
    },
    path: '/categories/create',
    name: 'categories.create',
    component: () => import('../views/categories/CategoriesCreate.vue')
  },
  {
    meta: {
      title: 'Ver Categoría'
    },
    path: '/categories/:id',
    name: 'categories.view',
    component: () => import('@/views/categories/CategoriesView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Categoría'
    },
    path: '/categories/:id/edit',
    name: 'categories.edit',
    component: () => import('@/views/categories/CategoriesEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Asignar Link'
    },
    path: '/categories/:id/assign_links',
    name: 'categories.assign_links',
    component: () => import('../views/categories/CategoriesAssignLinks.vue'),
    props: true
  },
  // Usuarios
  {
    meta: {
      title: 'Usuarios'
    },
    path: '/users',
    name: 'users',
    component: () => import('../views/users/Users.vue')
  },
  {
    meta: {
      title: 'Crear Usuario'
    },
    path: '/users/create',
    name: 'users.create',
    component: () => import('../views/users/UsersCreate.vue')
  },
  {
    meta: {
      title: 'Ver Usuario'
    },
    path: '/users/:id',
    name: 'users.view',
    component: () => import('@/views/users/UsersView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Usuario'
    },
    path: '/users/:id/edit',
    name: 'users.edit',
    component: () => import('@/views/users/UsersEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Asignar Roles'
    },
    path: '/users/:id/assign_roles',
    name: 'users.assign_roles',
    component: () => import('../views/users/UsersAssignRoles.vue'),
    props: true
  },
  // Tiendas
  {
    meta: {
      title: 'Tiendas'
    },
    path: '/stores',
    name: 'stores',
    component: () => import('../views/stores/Stores.vue')
  },
  // Localidades
  {
    meta: {
      title: 'Localidades'
    },
    path: '/localities',
    name: 'localities',
    component: () => import('../views/localities/Localities.vue')
  },
  {
    meta: {
      title: 'Crear Localidad'
    },
    path: '/localities/create',
    name: 'localities.create',
    component: () => import('../views/localities/LocalitiesCreate.vue')
  },
  {
    meta: {
      title: 'Editar Localidad'
    },
    path: '/localities/:id/edit',
    name: 'localities.edit',
    component: () => import('../views/localities/LocalitiesEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Crear Ciudad'
    },
    path: '/cities/create',
    name: 'cities.create',
    component: () => import('../views/localities/CitiesCreate.vue')
  },
  // Roles
  {
    meta: {
      title: 'Roles'
    },
    path: '/roles',
    name: 'roles',
    component: () => import('../views/roles/Roles.vue')
  },
  {
    meta: {
      title: 'Crear Rol'
    },
    path: '/roles/create',
    name: 'roles.create',
    component: () => import('../views/roles/RolesCreate.vue')
  },
  {
    meta: {
      title: 'Asignar Permisos'
    },
    path: '/roles/:id/assign_permissions',
    name: 'roles.assign_permissions',
    component: () => import('../views/roles/RolesAssignPermissions.vue'),
    props: true
  },
  {
    meta: {
      title: 'Crear Permiso'
    },
    path: '/permissions/create',
    name: 'permissions.create',
    component: () => import('../views/roles/PermissionsCreate.vue')
  },
  // Equipos
  {
    meta: {
      title: 'Equipos'
    },
    path: '/equipment',
    name: 'equipment',
    component: () => import('../views/equipment/Equipment.vue')
  },
  {
    meta: {
      title: 'Equipos'
    },
    path: '/equipment/create',
    name: 'equipment.create',
    component: () => import('../views/equipment/EquipmentCreate.vue')
  },
  // Comentarios
  {
    meta: {
      title: 'Editar Comentario'
    },
    path: '/comments/:id/edit',
    name: 'comments.edit',
    component: () => import('../views/CommentsEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Sobre Nosotros'
    },
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    meta: {
      title: 'Caja'
    },
    path: '/cash',
    name: 'cash',
    component: () => import('../views/cash/Cash.vue')
  },
  {
    meta: {
      title: 'Crear Registro'
    },
    path: '/cash/create/:type',
    name: 'cash.create',
    component: () => import('../views/cash/EntriesCreate.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Registro'
    },
    path: '/cash/edit/:id',
    name: 'cash.edit',
    component: () => import('../views/cash/EntriesEdit.vue'),
    props: true
  },
  // Cupones
  {
    meta: {
      title: 'Cupones'
    },
    path: '/coupons',
    name: 'coupons',
    component: () => import('../views/coupons/Coupons.vue')
  },
  {
    meta: {
      title: 'Cupones'
    },
    path: '/coupons/create',
    name: 'coupons.create',
    component: () => import('../views/coupons/CouponsCreate.vue')
  },
  {
    meta: {
      title: 'Ver Cupón'
    },
    path: '/coupons/:id',
    name: 'coupons.view',
    component: () => import('@/views/coupons/CouponsView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Mi Código'
    },
    path: '/coupons/code/:code',
    name: 'coupons.code',
    component: () => import('@/views/coupons/CouponsView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Cupón'
    },
    path: '/coupons/:id/edit',
    name: 'coupons.edit',
    component: () => import('../views/coupons/CouponsEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Empresa'
    },
    path: '/employees/organization',
    name: 'employees.organization',
    component: () => import('../views/employees/Organization.vue')
  },
  {
    meta: {
      title: 'Empresa'
    },
    path: '/organization',
    name: 'organization',
    component: () => import('../views/employees/Organization.vue')
  },
  {
    meta: {
      title: 'RRHH'
    },
    path: '/hr',
    name: 'hr',
    component: () => import('../views/hr/HRIndex.vue')
  },
  {
    meta: {
      title: 'Colaboradores'
    },
    path: '/hr/employees',
    name: 'employees',
    component: () => import('../views/employees/Employees.vue')
  },
  {
    meta: {
      title: 'Ver Colaborador'
    },
    path: '/hr/employees/:id',
    name: 'employees.view',
    component: () => import('@/views/employees/EmployeesView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Editar Colaborador'
    },
    path: '/hr/employees/:id/edit',
    name: 'employees.edit',
    component: () => import('../views/employees/EmployeesEdit.vue'),
    props: true
  },
  {
    meta: {
      title: 'Afiliado'
    },
    path: '/affiliate',
    name: 'affiliate',
    component: () => import('../views/profile/Affiliate.vue')
  },
  {
    meta: {
      title: 'Documentos'
    },
    path: '/docs',
    name: 'docs',
    component: () => import('../views/docs/Docs.vue'),
    props: true
  },
  {
    meta: {
      title: 'Ver Documento'
    },
    path: '/docs/:id',
    name: 'docs.view',
    component: () => import('@/views/docs/DocsView.vue'),
    props: true
  },
  {
    meta: {
      title: 'Documentos'
    },
    path: '/docs/upload',
    name: 'docs.upload',
    component: () => import('../views/docs/DocsUpload.vue'),
    props: true
  },
  {
    meta: {
      title: 'Activity Log'
    },
    path: '/logs',
    name: 'logs',
    component: () => import('../views/Logs.vue'),
    props: true
  },
  {
    meta: {
      title: 'Cargas'
    },
    path: '/uploads',
    name: 'uploads',
    component: () => import('../views/uploads/Uploads.vue'),
    props: true
  },
  {
    meta: {
      title: 'Carga Mes'
    },
    path: '/uploads/monthly/:date',
    name: 'uploads.monthly',
    component: () => import('../views/uploads/Monthly.vue'),
    props: true
  },
  {
    meta: {
      title: 'Mi Perfil'
    },
    path: '/profile',
    name: 'profile',
    component: () => import('../views/profile/Profile.vue')
  },
  {
    meta: {
      title: 'Inicia Sesión'
    },
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  document.title = to.meta.title;
  // next();

  if (to.name === 'login') {
    next() // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
  } else if (to.meta && to.meta.requiresAuth === false) {
    next() // requires auth is explicitly set to false
  } else if (store.state.user) {
    if (to.name === 'login') {
      next({ name: 'dashboard' }) // login route is always  okay (we could use the requires auth flag below). prevent a redirect loop
    } else {
      next() // i'm logged in. carry on
    }
  } else {
    next({ name: 'login' }) // always put your redirect as the default case
  }


})

export default router
