<template>
    <div class="bg-white" id="sidebar-wrapper">
        <div class="sidebar-heading text-center py-4 primary-text fs-4 fw-bold text-uppercase border-bottom">
            <router-link class="primary-text link-success text-decoration-none" to="/">
                <img src="@/assets/img/pz_logo.png" style="height:28px;" alt="" data-aos="zoom-out" data-aos-delay="300">
                Pastas Zena
            </router-link>
        </div>

        <div class="d-flex justify-content-center" v-if="isLoading">
            <div class="spinner-border m-5 text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div class="list-group list-group-flush">
            <div v-show="!isLoading">
                <span class="store-name list-group-item" :style="{ backgroundColor: storeColor }">{{ storeName }}</span>

                <router-link :class="{ active: (routeName == 'home') }"
                    class="mt-1 list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/">
                    <i class="fas fa-home-alt me-2"></i>{{ text.sidebar.home }}
                </router-link>

                <router-link v-permission="'customers-read'" :class="{ active: (customersActive) }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/customers">
                    <i class="fas fa-users me-2"></i>{{ text.sidebar.customers }}
                </router-link>

                <router-link v-permission="'orders-read'" :class="{ active: (ordersActive) }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/orders">
                    <i class="fas fa-clipboard-list me-2"></i>{{ text.sidebar.orders }}
                    <span v-if="getPendingOrders" class="badge bg-warning rounded-pill me-1">{{ getPendingOrders }}</span>
                    <span v-if="getPendingRequests" class="badge bg-danger rounded-pill ">{{ getPendingRequests }}</span>
                </router-link>

                <router-link v-permission="'transactions-read'" :class="{ active: (transactionsActive) }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/transactions">
                    <i class="fas fa-hand-holding-usd me-2"></i>{{ text.sidebar.transactions }}
                </router-link>

                <router-link v-permission="'cash-read'" :class="{ active: (cashActive) }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold" to="/cash">
                    <i class="fas fa-cash-register me-2"></i>{{ text.sidebar.cash }}
                </router-link>

                <router-link v-permission="'production-read'" :class="{ active: (productionActive) }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/production">
                    <i class="fas fa-industry me-2"></i>{{ text.sidebar.production }}
                </router-link>

                <router-link v-permission="'inventories-read'" :class="{ active: (inventoriesActive) }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/inventories">
                    <i class="fas fa-boxes me-2"></i>{{ text.sidebar.inventories }}
                </router-link>

                <router-link v-permission="'hr-read'" :class="{ active: (hrActive) }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/hr">
                    <i class="fas fa-briefcase me-2"></i>{{ text.sidebar.hr }}
                </router-link>

                <router-link v-permission="'analytics-read'" :class="{ active: (analyticsActive) }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/analytics">
                    <i class="fas fa-chart-bar me-2"></i>{{ text.sidebar.analytics }}
                </router-link>

                <router-link v-permission="'affiliate'" :class="{ active: (affiliateActive) }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/affiliate">
                    <i class="fas fa-gift me-2"></i>{{ text.sidebar.affiliate }}
                </router-link>

                <button v-permission="'admin-read'" id="adminBtn"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold collapsed"
                    data-bs-toggle="collapse" data-bs-target="#admin-collapse" aria-expanded="false">
                    <i class="fas fa-user-cog me-2"></i>Admin
                </button>
                <div class="collapse" :class="{ show: showAdmin }" id="admin-collapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small secondary-bg">
                        <li>
                            <router-link :class="{ active: (productsActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/products">
                                {{ text.sidebar.products }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (categoriesActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/categories">
                                {{ text.sidebar.categories }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (usersActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/users">
                                {{ text.sidebar.users }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (storesActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/stores">
                                {{ text.sidebar.stores }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (localitiesActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/localities">
                                {{ text.sidebar.localities }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (rolesActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/roles">
                                {{ text.sidebar.roles }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (equipmentActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/equipment">
                                {{ text.sidebar.equipment }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (couponsActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/coupons">
                                {{ text.sidebar.coupons }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (docsActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/docs">
                                {{ text.sidebar.docs }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (logsActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/logs">
                                {{ text.sidebar.log }}
                            </router-link>
                        </li>
                        <li>
                            <router-link :class="{ active: (uploadsActive) }"
                                class="list-group-item list-group list-group-item-action bg-transparent dark-text fw-bold"
                                to="/uploads">
                                {{ text.sidebar.uploads }}
                            </router-link>
                        </li>
                    </ul>
                </div>

                <router-link v-if="false" :class="{ active: (routeName == 'about') }"
                    class="list-group-item list-group list-group-item-action bg-transparent second-text fw-bold"
                    to="/about">
                    <i class="far fa-question-circle me-2"></i>{{ text.sidebar.about }}
                </router-link>

            </div>
            <a v-permission="'webmail'" href="https://webmail.pastaszena.cl" target="_blank"
                class="list-group-item list-group list-group-item-action bg-transparent text-primary fw-bold">
                <i class="far fa-envelope me-2"></i>{{ text.sidebar.webmail }}
            </a>

            <a @click="logout"
                class="logout list-group-item list-group list-group-item-action bg-transparent text-danger fw-bold">
                <i class="fas fa-sign-out-alt me-2"></i>{{ text.logout }}
            </a>
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'SiderBar',
    components: {},
    watch: {
        routeName() {
            this.checkAdminRoute()
            this.routeChanged()
            window.scroll(0, 0)
        },
        refreshStore() {
            //console.log('refreshStore')
            this.storeName = this.currentStore.name
            this.storeColor = this.currentStore.color
        },
        refreshKey() {
            //console.log('sidebar-refreshKey', this.refreshKey)
            this.fetchData()
        },
    },
    props: {
        routeName: {
            type: String,
            default: null
        },
        refreshStore: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            showAdmin: false,

            storeName: 'Tienda',
            storeColor: '#bbbec5',

            productsActive: false,
            categoriesActive: false,
            usersActive: false,
            storesActive: false,
            localitiesActive: false,
            transactionsActive: false,
            productionActive: false,
            customersActive: false,
            ordersActive: false,
            rolesActive: false,
            equipmentActive: false,
            couponsActive: false,
            inventoriesActive: false,
            cashActive: false,
            employeesActive: false,
            docsActive: false,
            logsActive: false,
            uploadsActive: false,
            analyticsActive: false,
            hrActive: false,
            affiliateActive: false,

        }
    },
    computed: {
        ...mapState('text', ['text']),
        ...mapGetters('stores', ['currentStore']),
        ...mapGetters(['isLoading']),
        ...mapGetters(['getPendingRequests']),
        ...mapGetters(['getPendingOrders']),
        ...mapGetters('autoRefresh', ['refreshKey']),
    },
    methods: {
        routeChanged() {
            let adminBtn = document.getElementById('adminBtn');
            if (adminBtn.getAttribute('aria-expanded') == 'true') {
                adminBtn.click()
            }
        },
        logout() {
            this.$store.dispatch('user/logout')
            this.$store.dispatch('autoRefresh/stopDataUpdates')
            this.$router.push('/login')
        },
        checkAdminRoute() {
            this.productsActive = false
            this.categoriesActive = false
            this.usersActive = false
            this.storesActive = false
            this.localitiesActive = false
            this.transactionsActive = false
            this.productionActive = false
            this.customersActive = false
            this.ordersActive = false
            this.rolesActive = false
            this.equipmentActive = false
            this.inventoriesActive = false
            this.couponsActive = false
            this.cashActive = false
            this.employeesActive = false
            this.docsActive = false
            this.logsActive = false
            this.uploadsActive = false
            this.analyticsActive = false
            this.hrActive = false
            this.affiliateActive = false

            this.showAdmin = false

            if (this.routeName == 'products' || this.routeName == 'products.create' || this.routeName == 'products.edit' || this.routeName == 'products.prices') {
                this.productsActive = true
                this.showAdmin = true
            } else if (this.routeName == 'categories' || this.routeName == 'categories.create' || this.routeName == 'categories.view' || this.routeName == 'categories.edit' || this.routeName == 'categories.assign_links') {
                this.categoriesActive = true
                this.showAdmin = true
            } else if (this.routeName == 'users' || this.routeName == 'users.create' || this.routeName == 'users.edit' || this.routeName == 'users.view' || this.routeName == 'users.assign_roles' || this.routeName == 'users.create_profile') {
                this.usersActive = true
                this.showAdmin = true
            } else if (this.routeName == 'stores') {
                this.storesActive = true
                this.showAdmin = true
            } else if (this.routeName == 'localities' || this.routeName == 'localities.create' || this.routeName == 'localities.edit' || this.routeName == 'cities.create') {
                this.localitiesActive = true
                this.showAdmin = true
            } else if (this.routeName == 'coupons' || this.routeName == 'coupons.view' || this.routeName == 'coupons.create' || this.routeName == 'coupons.edit') {
                this.couponsActive = true
                this.showAdmin = true
            } else if (this.routeName == 'roles' || this.routeName == 'roles.create' || this.routeName == 'roles.assign_permissions' || this.routeName == 'permissions.create') {
                this.rolesActive = true
                this.showAdmin = true
            } else if (this.routeName == 'equipment' || this.routeName == 'equipment.create') {
                this.equipmentActive = true
                this.showAdmin = true
            } else if (this.routeName == 'employees' || this.routeName == 'employees.view' || this.routeName == 'employees.edit' || this.routeName == 'employees.organization') {
                this.hrActive = true
                //this.showAdmin = true
            } else if (this.routeName == 'docs' || this.routeName == 'docs.view' || this.routeName == 'docs.upload') {
                this.docsActive = true
                this.showAdmin = true
            } else if (this.routeName == 'logs') {
                this.logsActive = true
                this.showAdmin = true
            } else if (this.routeName == 'uploads' || this.routeName == 'uploads.monthly') {
                this.uploadsActive = true
                this.showAdmin = true
            } else if (this.routeName == 'transactions' || this.routeName == 'transactions.create' || this.routeName == 'transactions.edit') {
                this.transactionsActive = true
            } else if (this.routeName == 'cash' || this.routeName == 'cash.create' || this.routeName == 'cash.edit') {
                this.cashActive = true
            } else if (this.routeName == 'production' || this.routeName == 'production.history' || this.routeName == 'production.history.month' || this.routeName == 'flows' || this.routeName == 'flows.create' || this.routeName == 'flows.edit' || this.routeName == 'flows.view' || this.routeName == 'activities.create' || this.routeName == 'activities.edit' || this.routeName == 'activities.view' || this.routeName == 'instances.create' || this.routeName == 'instances.view' || this.routeName == 'instances.edit') {
                this.productionActive = true
            } else if (this.routeName == 'customers' || this.routeName == 'customers.create' || this.routeName == 'customers.edit' || this.routeName == 'customers.add_address' || this.routeName == 'customers.view' || this.routeName == 'companies' || this.routeName == 'companies.create' || this.routeName == 'companies.view' || this.routeName == 'companies.edit') {
                this.customersActive = true
            } else if (this.routeName == 'orders' || this.routeName == 'orders.create' || this.routeName == 'orders.edit' || this.routeName == 'orders.view' || this.routeName == 'requests' || this.routeName == 'requests.view' || this.routeName == 'orders.history' || this.routeName == 'orders.history.month' || this.routeName == 'orders.prepare' || this.routeName == 'orders.handover' || this.routeName == 'orders.share' || this.routeName == 'orders.invoice' || this.routeName == 'orders.pending') {
                this.ordersActive = true
            } else if (this.routeName == 'analytics' || this.routeName == 'analytics.daily' || this.routeName == 'analytics.monthly' || this.routeName == 'analytics.reports') {
                this.analyticsActive = true
            } else if (this.routeName == 'hr') {
                this.hrActive = true
            } else if (this.routeName == 'affiliate') {
                this.affiliateActive = true
            } else if (this.routeName == 'inventories' || this.routeName == 'inventories.history' || this.routeName == 'inventories.history.month' || this.routeName == 'inventories.create' || this.routeName == 'inventories.edit' || this.routeName == 'inventories.view' || this.routeName == 'items' || this.routeName == 'items.create' || this.routeName == 'items.edit' || this.routeName == 'items.assign' || this.routeName == 'inventories.summary' || this.routeName == 'inventories.date' || this.routeName == 'levels' || this.routeName == 'levels.create' || this.routeName == 'levels.edit') {
                this.inventoriesActive = true
            }
        },
        fetchData() {
            this.$store.dispatch('fetchPendingRequestsCount')
            this.$store.dispatch('fetchPendingOrdersCount')
        },
        startDataUpdates() {
            this.$store.dispatch('autoRefresh/startDataUpdates')
        },
    },
    mounted() {
        this.checkAdminRoute()
        if (this.currentStore) {
            this.storeName = this.currentStore.name
            this.storeColor = this.currentStore.color
        }
        this.fetchData()
        this.startDataUpdates()
    },
    beforeUnmount() {
        this.$store.dispatch('autoRefresh/stopDataUpdates')
    },
    created() {
        this.$store.watch(
            (state) => state.store,
            () => {
                this.storeName = this.currentStore.name
                this.storeColor = this.currentStore.color
            }
        )
    },
})

</script>