export default {
    // Sidebar & Header
    sidebar: {
        //dashboard: 'Dashboard',
        home: 'Inicio',
        orders: 'Pedidos',
        customers: 'Clientes',
        inventories: 'Inventario',
        transactions: 'Transacciones',
        production: 'Producción',
        analytics: 'Analítica',
        about: 'About',
        cash: 'Caja',
        webmail: 'Webmail',
        hr: 'RRHH',
        affiliate: 'Afiliado',
        // Admin
        products: 'Productos',
        categories: 'Categorías',
        users: 'Usuarios',
        stores: 'Tiendas',
        localities: 'Localidades',
        roles: 'Roles',
        equipment: 'Equipos',
        coupons: 'Cupones',
        employees: 'Colaboradores',
        docs: 'Documentos',
        documents: 'Documentos',
        log: 'Activity Log',
        uploads: 'Cargas Haulmer',
    },
    headersKv: [
        //{ key: 'dashboard',  value: 'Dashboard' },
        { key: 'home', value: 'Inicio' },
        //Pedidos
        { key: 'orders', value: 'Pedidos Vigentes' },
        { key: 'orders.create', value: 'Ingresar Pedido' },
        { key: 'orders.edit', value: 'Editar Pedido' },
        { key: 'orders.view', value: 'Ver Pedido' },
        { key: 'orders.prepare', value: 'Preparar Pedido' },
        { key: 'orders.handover', value: 'Entregar Pedido' },
        { key: 'orders.history', value: 'Historial Pedidos' },
        { key: 'orders.pending', value: 'Pedidos Pendientes' },
        { key: 'orders.history.month', value: 'Historial Pedidos' },
        { key: 'orders.share', value: 'Vista Cliente' },
        { key: 'orders.invoice', value: 'Vista Factura' },
        { key: 'requests', value: 'Peticiones' },
        { key: 'requests.view', value: 'Ver Petición' },
        // Clientes
        { key: 'customers', value: 'Clientes' },
        { key: 'customers.create', value: 'Ingresar Cliente' },
        { key: 'customers.edit', value: 'Editar Cliente' },
        { key: 'customers.view', value: 'Ver Cliente' },
        { key: 'customers.add_address', value: 'Agregar Dirección' },
        { key: 'customers.addresses.edit', value: 'Editar Dirección' },
        { key: 'companies', value: 'Restaurantes' },
        { key: 'companies.create', value: 'Ingresar Restaurante' },
        { key: 'companies.view', value: 'Ver Restaurante' },
        { key: 'companies.edit', value: 'Editar Restaurante' },
        // Inventarios
        { key: 'inventories', value: 'Inventarios' },
        { key: 'inventories.create', value: 'Crear Inventario' },
        { key: 'inventories.edit', value: 'Editar Inventario' },
        { key: 'inventories.view', value: 'Ver Inventario' },
        { key: 'inventories.summary', value: 'Inv. Consolidados' },
        { key: 'inventories.date', value: 'Inv. Consolidados' },
        { key: 'inventories.history', value: 'Historial Inventarios' },
        { key: 'inventories.history.month', value: 'Historial Inventarios' },
        { key: 'levels', value: 'Límites de Stock' },
        { key: 'levels.create', value: 'Crear Límite' },
        { key: 'levels.edit', value: 'Editar Límite' },
        { key: 'items', value: 'Items' },
        { key: 'items.create', value: 'Crear Item' },
        { key: 'items.assign', value: 'Asignar Item' },
        { key: 'items.edit', value: 'Editar Item' },
        // Transacciones
        { key: 'transactions', value: 'Transacciones' },
        { key: 'transactions.create', value: 'Ingresar Transacción' },
        { key: 'transactions.edit', value: 'Editar Transacción' },
        // Producción
        { key: 'production', value: 'Producción' },
        { key: 'production.history', value: 'Historial Producción' },
        { key: 'production.history.month', value: 'Historial Producción' },
        { key: 'flows', value: 'Flujos' },
        { key: 'flows.create', value: 'Crear Flujo' },
        { key: 'flows.view', value: 'Ver Flujo' },
        { key: 'flows.edit', value: 'Editar Flujo' },
        { key: 'activities.create', value: 'Crear Actividad' },
        { key: 'activities.view', value: 'Ver Actividad' },
        { key: 'activities.edit', value: 'Editar Actividad' },
        { key: 'instances.create', value: 'Crear Instancia' },
        { key: 'instances.view', value: 'Ver Instancia' },
        { key: 'instances.edit', value: 'Editar Instancia' },

        // RRHH
        { key: 'hr', value: 'RRHH' },
        // Analítica
        { key: 'analytics', value: 'Analítica' },
        { key: 'analytics.daily', value: 'Analítica' },
        { key: 'analytics.monthly', value: 'Analítica' },
        { key: 'analytics.reports', value: 'Reportes' },
        // Productos
        { key: 'products', value: 'Productos' },
        { key: 'products.create', value: 'Crear Producto' },
        { key: 'products.edit', value: 'Editar Producto' },
        { key: 'products.prices', value: 'Editar Precios' },
        // Categorías
        { key: 'categories', value: 'Categorías' },
        { key: 'categories.create', value: 'Crear Categoría' },
        { key: 'categories.view', value: 'Ver Categoría' },
        { key: 'categories.edit', value: 'Editar Categoría' },
        { key: 'categories.assign_links', value: 'Asignar Links' },
        // Usuarios
        { key: 'users', value: 'Usuarios' },
        { key: 'users.create', value: 'Crear Usuario' },
        { key: 'users.view', value: 'Usuario' },
        { key: 'users.edit', value: 'Editar Usuario' },
        { key: 'users.assign_roles', value: 'Asignar Roles' },
        { key: 'profiles.edit', value: 'Editar Perfil' },
        // Tiendas
        { key: 'stores', value: 'Tiendas' },
        // Localidades
        { key: 'localities', value: 'Localidades' },
        { key: 'localities.create', value: 'Crear Localidad' },
        { key: 'localities.edit', value: 'Editar Localidad' },
        { key: 'cities.create', value: 'Crear Ciudad' },
        // Roles & Permisos
        { key: 'roles', value: 'Roles' },
        { key: 'permissions', value: 'Permisos' },
        { key: 'roles.assign_permissions', value: 'Asignar Permisos' },
        { key: 'roles.create', value: 'Crear Rol' },
        { key: 'permissions.create', value: 'Crear Permiso' },

        //Equipos (Maquinaria, Almacenamiento)
        { key: 'equipment', value: 'Equipos' },
        { key: 'equipment.create', value: 'Agregar Equipo' },

        //Caja
        { key: 'cash', value: 'Caja' },
        { key: 'cash.create', value: 'Ingresar Registro' },
        { key: 'cash.edit', value: 'Editar Registro' },

        // Cupones
        { key: 'coupons', value: 'Cupones' },
        { key: 'coupons.create', value: 'Crear Cupón' },
        { key: 'coupons.view', value: 'Ver Cupón' },
        { key: 'coupons.edit', value: 'Editar Cupón' },
        { key: 'coupons.code', value: 'Mi Código' },

        { key: 'affiliate', value: 'Perfil Afiliado' },

        //Empleados
        { key: 'employees', value: 'Colaboradores' },
        { key: 'employees.view', value: 'Ver Colaborador' },
        { key: 'employees.edit', value: 'Editar Colaborador' },
        { key: 'employees.organization', value: 'Empresa' },
        { key: 'organization', value: 'Empresa' },

        //Docs
        { key: 'docs', value: 'Documentos' },
        { key: 'docs.upload', value: 'Cargar Documento' },
        { key: 'docs.view', value: 'Ver Documento' },

        //Otros
        { key: 'profile', value: 'Mi Perfil' },
        { key: 'comments.edit', value: 'Editar Comentario' },
        { key: 'about', value: 'About' },
        { key: 'logs', value: 'Activity Log' },

        { key: 'uploads', value: 'Cargas' },
        { key: 'uploads.monthly', value: 'Carga Mensual' },
    ],

    // General
    name: 'Nombre',
    submit: 'Enviar',
    confirm: 'Confirmar',
    hidden: 'Oculto',
    save: 'Guardar',
    close: 'Cerrar',
    delete: 'Eliminar',
    logout: 'Cerrar Sesión',
    firstname: 'Primer Nombre',
    middlename: 'Segundo Nombre',
    lastname: 'Apellido',
    lastname1: 'Apellido Paterno',
    lastname2: 'Apellido Materno',
    email: 'Correo',
    phone: 'Teléfono',
    actions: 'Acciones',
    date: 'Fecha',
    store: 'Tienda',
    total: 'Total',
    shortname: 'Abreviatura',
    undefined: 'No definido',
    insertedBy: 'Ingresado por',
    modifiedBy: 'Modificado por',
    insertedAt: 'Ingresado',
    modifiedAt: 'Modificado',
    enabled: 'Habilitado',
    yes: 'Sí',
    no: 'No',
    description: 'Descripción',
    title: 'Título',
    subtitle: 'Subtítulo',
    slug: 'Slug',
    generate: 'Generar',
    product: 'Producto',
    products: 'Productos',
    selectOption: 'Selecciona una opción',
    unit: 'Unidad',
    weight: 'Peso',
    toLink: 'Enlazar',
    value: 'Valor',
    measure: 'Medida',
    count: 'Conteo',
    id: 'Identificador',
    address: 'Dirección',
    coordinates: 'Coordenadas',
    optional: 'Opcional',
    additionalInfo: 'Información Adicional',
    detail: 'Detalle',
    details: 'Detalles',
    info: 'Info.',
    information: 'Información',
    customerInfo: 'Información Cliente',
    orderInfo: 'Información Pedido',
    deliveryInfo: 'Información Reparto',
    documentId: 'RUT',
    birthdate: 'Fecha nacimiento',
    gender: 'Género',
    male: 'Masculino',
    female: 'Femenino',
    quantity: 'Cantidad',
    comments: 'Comentarios',
    status: 'Estado',
    history: 'Historial',
    createdAt: 'Creado en',
    uploadedAt: 'Subido el',
    subtotal: 'Subtotal',
    next: 'Siguiente',
    previous: 'Anterior',
    and: 'y',
    label: 'Etiqueta',
    reject: 'Rechazar',
    download: 'Descargar Archivo',
    summary: 'Consolidado',
    completeTheFields: 'Completa los campos',
    of: 'de',
    consolidated: 'Consolidado',
    goto: 'Ir a',
    type: 'Tipo',
    page: 'Pág.',
    active: 'Activo',
    year: 'Año',
    month: 'Mes',

    //Home
    sales: 'Ventas',
    production: 'Producción',

    // Header Dropdown
    switchStore: 'Cambiar Tienda',

    // Exclusivo Usuarios
    emailSent: 'Correo enviado',

    // Exclusivo Productos
    createProduct: 'Crear Producto',
    price: 'Precio',
    prices: 'Precios',
    storageType: 'Tipo de Almacenamiento',
    productCreated: 'Producto creado',
    productUpdated: 'Producto actualizado',
    productName: 'Nombre Producto',
    tablePrice: 'Precio Tabla',
    normalPrice: 'Precio Normal',
    wholesalePrice: 'Precio Mayorista',
    rappiPrice: 'Precio Rappi',
    offerPrice: 'Precio Oferta',
    offer: 'Oferta',
    priceDeleted: 'Precio Eliminado',

    // Exclusivo Categorías
    createCategory: 'Crear Categoría',
    categoryName: 'Nombre Categoría',
    categoryParent: 'Categoría Madre',
    noCategoryParent: 'Sin Categoría Madre',
    categoryCreated: 'Categoría creada',
    categoryUpdated: 'Categoría actualizada',
    category: 'Categoría',
    categoryLinkType: 'Tipo de Item',
    categoryLink: 'Nombre del Item',
    linkAssigned: 'Item asignado',
    subcategories: 'Subcategorías',
    noCategory: 'Sin Categoría',

    // Exclusivo Usuarios
    user: 'Usuario',
    createUser: 'Crear Usuario',
    editUser: 'Editar Usuario',
    password: 'Contraseña',
    passwordConfirm: 'Confirmar Contraseña',
    userCreated: 'Usuario creado',
    assignRoles: 'Asignar Roles',
    admin_panel_access: 'Acceso Admin Panel',
    createProfile: 'Crear Perfil',
    editProfile: 'Editar Perfil',
    canDelivery: 'Puede Repartir',
    emailVerified: 'Correo Verificado',
    userUpdated: 'Usuario actualizado',
    customerId: 'ID Cliente',
    employeeId: 'ID Colaborador',
    noCustomerId: 'Sin ID Cliente',
    noEmployeeId: 'Sin ID Colaborador',
    viewCustomer: 'Ver Cliente',
    viewEmployee: 'Ver Colaborador',
    profile: 'Perfil',
    viewProfile: 'Ver Perfil',
    insertPassword: 'Ingresa tu Contraseña',

    // Exclusivo Transactions
    createTransaction: 'Ingresar Transacción',
    transactionDate: 'Fecha Transacción',
    addProduct: 'Agregar Producto',
    selectProduct: 'Selecciona un Producto',
    selectStore: 'Selecciona una Tienda',
    transactionCreated: 'Transacción creada',
    transactionUpdated: 'Transacción actualizada',
    paymentMethod: 'Método de Pago',
    selectPaymentMethod: 'Selecciona un Método de Pago',
    paysWith: 'Paga con',
    paymentChange: 'Vuelto',
    lastTransactions: 'Últimas Transacciones',
    transactions: 'Transacciones',
    transaction: 'Transacción',

    // Exclusivo Producción
    createFlow: 'Crear Flujo',
    flowName: 'Nombre Flujo',
    flowCreated: 'Flujo creado',
    flowUpdated: 'Flujo editado',
    flowDeleted: 'Flujo eliminado',
    editFlow: 'Editar Flujo',
    createActivity: 'Crear Actividad',
    deleteActivity: 'Eliminar Actividad',
    deleteActivityConfirm: '¿Estás seguro de elminar esta actividad? Si la eliminas se eliminarán sus instancias asociadas',
    editActivity: 'Editar Actividad',
    activityName: 'Nombre Actividad',
    activityCreated: 'Actividad creada',
    activityUpdated: 'Actividad actualizada',
    activityDeleted: 'Actividad eliminada', 
    inputMeasure: 'Medida Entrada',
    outputMeasure: 'Medida Salida',
    conversionRate: 'Tasa Conversión',
    createInstance: 'Crear Instancia',
    editInstance: 'Editar Instancia',
    flow: 'Flujo',
    selectFlow: 'Selecciona un Flujo',
    activity: 'Actividad',
    selectActivity: 'Selecciona una Actividad',
    instanceCreated: 'Instancia creada',
    instanceUpdated: 'Instancia actualizada',
    instance: 'Instancia',
    instances: 'Instancias',
    lastInstances: 'Últimas Instancias',
    bg_color: 'Color de Fondo',
    text_color: 'Color de Texto',
    noPendingInstances: 'No hay tareas para hoy',
    deleteFlow: 'Eliminar Flujo',
    deleteFlowConfirm: '¿Estás seguro de elminar este flujo?',

    // Exclusivo Customers
    createCustomer: 'Crear Cliente',
    customerFirstname: 'Primer Nombre',
    customerLastname: 'Apellido Paterno',
    customerLastname2: 'Apellido Materno',
    customerRUT: 'RUT',
    customerCreated: 'Cliente creado',
    customerUpdated: 'Cliente actualizado',
    addAddress: 'Agregar Dirección',
    editCustomer: 'Editar Cliente',
    companies: 'Restaurantes',
    createCompany: 'Crear Restaurante',
    companyName: 'Razón social',
    companyDocId: 'R.U.T.',
    companyFantasyName: 'Nombre Fantasía',
    companyActivty: 'Giro',
    companyAddress: 'Dirección Empresa',
    companyCreated: 'Restaurant creado',
    selectCompany: 'Selecciona el restaurant',
    editCompany: 'Editar Restaurante',
    noCompany: 'Quitar asociación a restaurant',
    companyUpdated: 'Restaurant actualizado',
    deleteCustomer: 'Eliminar Cliente',
    customerDeleted: 'Cliente Eliminado',
    associatedRestaurant: 'Restaurant Asociado',
    customerAddresses: 'Direcciones de despacho asociadas',
    restaurant: 'Restaurante',
    deleteCompany: 'Eliminar Restaurante',
    companyDeleted: 'Restaurante Eliminado',
    downloadCustomers: 'Descargar Clientes',
    downloadAddresses: 'Descargar Direcciones',

    // Localidades, Ciudades y Regiones
    localities: 'Localidades',
    locality: 'Localidad',
    createLocality: 'Crear Localidad',
    selectLocality: 'Selecciona una Localidad',
    localityCreated: 'Localidad creada',
    localityUpdated: 'Localidad actualizada',
    cities: 'Ciudades',
    city: 'Ciudad',
    createCity: 'Crear Ciudad',
    selectCity: 'Selecciona una Ciudad',
    cityCreated: 'Ciudad creada',
    cityUpdated: 'Ciudad actualizada',
    regions: 'Regiones',
    region: 'Región',
    selectRegion: 'Selecciona una Región',
    regionCreated: 'Ciudad creada',
    regionUpdated: 'Ciudad actualizada',
    delivery_price: 'Precio Reparto',

    // Addresses
    streetAndNumber: 'Nombre y Número de Calle',
    streetName: 'Nombre de Calle',
    streetNumber: 'Número de Calle',
    streetAdditional: 'Depto/Casa/Otro',
    addressCreated: 'Dirección creada',
    addressUpdated: 'Dirección actualizada',

    // Exclusivo Pedidos
    order: 'Pedido',
    orders: 'Pedidos',
    createOrder: 'Crear Pedido',
    orderDate: 'Fecha de Entrega',
    customer: 'Cliente',
    selectCustomer: 'Selecciona un Cliente',
    orderType: 'Tipo de Pedido',
    orderDeliveryAddress: 'Dirección de Entrega',
    selectAddress: 'Selecciona una Dirección',
    orderCreated: 'Pedido creado',
    orderUpdated: 'Pedido actualizado',
    orderEdit: 'Editar Pedido',
    orderStatus: 'Estado Pedido',
    paymentStatus: 'Estado Pago',
    deliveryMan: 'Repartidor',
    pickup: 'Retiro',
    delivery: 'Despacho',
    requests: 'Peticiones',
    customerName: 'Nombre Cliente',
    pendingRequests: 'Peticiones Pendientes',
    approvedRequests: 'Peticiones Aprobadas',
    rejectedRequests: 'Peticiones Rechazadas',
    linkExistingOrder: 'Enlazar Pedido Existente',
    linkCustomer: 'Asociar Cliente',
    linkAddress: 'Asociar Dirección',
    customerLinked: 'Información Cliente Asociada',
    orderTotal: 'Total Pedido',
    totalWithoutDelivery: 'Total Pedido (Sin reparto)',
    noPendingOrders: 'No hay pedidos vigentes',
    markAsPaid: '¿Quieres marcar este pedido como pagado?',
    markAsPaidBtn: 'Marcar como pagado',
    markAsSent: '¿Quieres marcar este pedido en ruta?',
    orderPrepare: 'Preparar Pedido',
    orderHandover: 'Entregar Pedido',
    orderDelete: 'Eliminar Pedido',
    customerView: 'Vista Cliente',
    invoiceView: 'Vista Factura',
    copyToClipboard: 'Copiar al portapapeles',
    copy: 'Copiar',
    orderView: 'Ver Pedido',
    loadAllCustomers: 'Refrescar clientes', 
    showOtherStores: 'Mostrar Otras Tiendas',
    showHandedOver: 'Mostrar Entregados',
    lastOrders: 'Últimos Pedidos',
    monthlyInfo: 'Información Mensual',
    definitiveOrderId: 'ID Pedido enlazado',
    pendingOrders: 'P. Pendientes',
    orderId: 'ID Pedido',
    notes: 'Notas',
    deleteOrderConfirm: '¿Estás seguro de elminar este pedido?',
    generateCustomerConfirm: '¿Estás seguro de querer generar un nuevo cliente? Puede que ya exista',
    generateAddressConfirm: '¿Estás seguro de querer generar una nueva dirección? Puede que ya exista',
    addNewProduct: 'Agregar nuevo producto',
    unitPrice: 'Precio Unitario',
    invoice: {
        generals: 'Generales',
        recipientInfo: 'Datos Receptor',
        details: 'Detalles del documento',
        totals: 'Totales',
        totalAmount: 'Monto Total',
        netAmount: 'Monto Neto',
        payment_method: 'Forma de pago',
        single_payment: 'Contado',
    },
    wholesalePricesApplied: 'Precios de mayorista aplicados',
    rappiPricesApplied: 'Precios de rappi aplicados',
    offerPricesApplied: 'Precios de oferta aplicados',
    noEmailForPaymentLink: 'Si el cliente no tiene email registrado no se puede generar link de pago',

    // Roles & Permissions
    roleName: 'Nombre de Rol',
    role: 'Rol',
    roles: 'Roles',
    createRole: 'Crear Rol',
    permissionTitle: 'Título de Permiso',
    permissionSlug: 'Slug de Permiso',
    permissions: 'Permisos',
    createPermission: 'Crear Permiso',
    roleCreated: 'Rol creado',
    permissionCreated: 'Permiso creado',
    roleLinked: 'Rol asignado',
    permissionLinked: 'Permiso asignado',

    //Equipos
    hasTempControl: 'Control de Temperatura',
    isMachineryUnit: 'Maquinaria',
    isStorageUnit: 'Almacenamiento',
    addEquipment: 'Agregar Equipo',
    equipmentCreated: 'Equipo Ingresado',

    //Inventario
    inventories: 'Inventarios',
    criticalInventory: 'Inventario Crítico',
    lastInventory: 'Última fecha de Inventario',
    createInventory: 'Crear Inventario',
    inventoryType: 'Tipo de Inventario',
    inventoryCreated: 'Inventario Creado',
    inventoryUpdated: 'Inventario Actualizado',
    createItem: 'Crear Item',
    items: 'Items',
    itemName: 'Nombre del Item',
    itemStage: 'Etapa del Item',
    itemUnit: 'Unidad del Item',
    itemWeight: 'Peso del Item',
    numericalMandatory: 'Valor numérico obligatorio',
    itemCreated: 'Item Creado',
    item: 'Item',
    inventoryItem: 'Item de Inventario',
    itemAssigned: 'Item Asignado',
    selectInventoryType: 'Selecciona un Tipo de Inventario',
    selectItemStage: 'Selecciona una Etapa',
    selectItemUnit: 'Selecciona una Unidad',
    inventory: 'Inventario',
    editInventory: 'Editar Inventario',
    itemUpdated: 'Item Actualizado',
    associatedInventories: 'Inv. Asociados',
    itemLevelLow: 'Bajo',
    itemLevelMedium: 'Medio',
    itemLevelHigh: 'Alto',
    inventoriesIncluded: 'Inventarios considerados',
    stockLevels: 'Límites de Stock',
    createStockLevel: 'Crear Límite',
    dangerLevel: 'Nivel Crítico',
    minLevel: 'Nivel Mínimo',
    averageLevel: 'Nivel Promedio',
    maxLevel: 'Nivel Máximo',
    levelCreated: 'Nivel Creado',
    levelUpdated: 'Nivel Actualizado',

    // Comentarios
    addComment: 'Agregar Comentario',
    newComment: 'Nuevo Comentario',
    editComment: 'Editar Comentario',
    commentUpdated: 'Comentario Actualizado',
    commentCreated: 'Comentario Creado',
    noComments: 'Sin Comentarios',

    // Exclusivo Cupones
    createCoupon: 'Crear Cupón',
    coupon: 'Cupón',
    myCoupons: 'Mis Cupones',
    code: 'Código',
    discount: 'Descuento',
    couponCreated: 'Cupón Creado',
    affiliate: 'Usuario Afiliado',
    couponOrders: 'Pedidos asociados',
    editCoupon: 'Editar Cupón',
    couponUpdated: 'Cupón Actualizado',
    non_affiliate: 'Sin Usuario Afiliado',
    commission: 'Comisión',
    linkCoupon: 'Enlazar Cupón',
    linkCouponMsg: 'Enlazar un cupón no implica una modificación en los descuentos de un pedido. Estos descuentos se deben realizar de forma manual editando el pedido',

    // Caja
    createEntry: 'Ingresar Registro',
    entryType: 'Tipo de Entrada',
    amount: 'Monto',
    cashBalances: 'Balances de Caja',
    cashMovements: 'Movimientos de Caja',
    createMovement: 'Registrar Movimiento',
    entryLabelBalance: 'Tipo de Balance',
    balance: {
        newEntry: 'Nuevo Balance',
        editingEntry: 'Editando Balance',
        selectLabel: 'Tipo de Balance',
        selectNullOption: 'Selecciona el Tipo de Balance',
        options: [
            { key: 'opening', value: 'Apertura' },
            { key: 'closing', value: 'Cierre' },
        ],
        entryCreated: 'Balance registrado',
        entryUpdated: 'Balance actualizado',
    },
    withdrawal: {
        newEntry: 'Nuevo Retiro',
        editingEntry: 'Editando Retiro',
        selectLabel: 'Tipo de Retiro',
        selectNullOption: 'Selecciona el Tipo de Retiro',
        options: [
            { key: 'withdrawal:juan', value: 'Retiro Juan' },
            { key: 'withdrawal:gio', value: 'Retiro Gio' },
            { key: 'payment', value: 'Pago proveedor' },
        ],
        entryCreated: 'Retiro registrado',
        entryUpdated: 'Retiro actualizado',
    },

    // Exclusivo Empleados
    jobTitle: 'Cargo',
    firstDay: 'Primer día',
    manager: 'Manager',
    mainStore: 'Tienda Principal',
    otherStoresAccess: 'Acceso a Otras Tiendas',
    employeeCreated: 'Info Empleado ingresada',
    employeeUpdated: 'Info Empleado actualizada',
    employee: 'Colaborador',
    editEmployee: 'Editar Colaborador',
    organization: 'Empresa',
    company: 'Empresa',
    organizationChart: 'Organigrama',
    activityOrgChart: 'Organigrama de Funciones',
    locationOrgChart: 'Organigrama de Local',
    activityOrgChartExplanation: 'El "Organigrama de Funciones" se enfoca en la estructura organizativa de la empresa según las actividades y roles funcionales desempeñados por los colaboradores.',
    locationOrgChartExplanation: 'El "Organigrama de Local" se centra en la estructura jerárquica y de autoridad de la empresa según las diferentes ubicaciones en las que opera.',

    // Exclusivo Logs
    userId: 'ID Usuario',
    activityType: 'Tipo de Actividad',

    // Exclusivo Analytics
    analytics: 'Analítica',
    reports: 'Reportes',
    includeTransactions: 'Incluir Transacciones',
    includeOrders: 'Incluir Pedidos',
    startDate: 'Fecha Inicio',
    endDate: 'Fecha Término',
    generateReport: 'Generar Reporte',

    // Exclusivo Docs
    uploadDocument: 'Cargar Documento',
    selectDocFile: 'Selecciona el archivo del documento',
    documentName: 'Nombre del documento',
    documentType: 'Tipo de documento',
    documentVisibility: 'Visibilidad del documento',
    pathSubfolder: 'Nombre del documento',
    docUploaded: 'Documento Ingresado',
    fileAlreadyExists: 'El archivo ya existe, prueba con otro nombre',
    documentPath: 'Ruta del documento',
    associatedEmployee: 'Colaborador Asociado',
    docDeleted: 'Documento Borrado',
    docs: 'Documentos',
    document: 'Documento',
    myDocs: 'Mis Documentos',
    showDocs: 'Mostrar Documentos',
    hideDocs: 'Ocultar Documentos',

    // Exclusivo RRHH
    employees: 'Colaboradores',
    showResources: 'Mostrar Recursos',
    hideResources: 'Ocultar Recursos',

    // Exclusivo Cargas
    importCsv: 'Importar CSV',
    uploads: 'Cargas Haulmer',

    //editComment: 'Editar Comentario',
    //commentUpdated: 'Comentario Actualizado',

    // KeyValues: 
    paymentStatusKv: [
        { key: 0, value: 'Pendiente de Pago', badge: 'bg-warning text-dark' },
        { key: 1, value: 'Pagado', badge: 'bg-success' },
    ],
    orderStatusKv: [
        { key: 0, value: 'Pendiente', badge: 'bg-warning text-dark' },
        { key: 1, value: 'Preparado', badge: 'bg-primary' },
        { key: 2, value: 'En Ruta', badge: 'bg-info ' },
        { key: 3, value: 'Entregado', badge: 'bg-success' },
        { key: 4, value: 'Cancelado', badge: 'bg-danger' },
    ],
    inventoryTypesKv: [
        { key: 1, shortname: 'P. Fin'   , value: 'Diario (Productos Finales)' },
        { key: 2, shortname: 'Prod.'    , value: 'Diario (Producción)' },
        { key: 3, shortname: 'Ventas'   , value: 'Semanal Ventas' },
        { key: 4, shortname: 'Cocina'   , value: 'Semanal Cocina' },
    ],
    itemStagesKv: [
        { key: 1, value: 'Insumo Primario' },
        { key: 2, value: 'Insumo Secundario' },
        { key: 3, value: 'Producción' },
        { key: 4, value: 'Producto Final' },
    ],
    itemUnitsKv: [
        { key: 'kg', value: 'Kilogramo' },
        { key: 'L', value: 'Litro' },
        { key: 'gr', value: 'Gramo' },
        { key: 'unit', value: 'Unidad' },
        { key: 'basket', value: 'Canasto' },
        { key: 'tray', value: 'Bandeja' },
        { key: 'box', value: 'Caja' },
        { key: 'sack', value: 'Saco' },
        { key: 'can', value: 'Bidón' },
        { key: 'bottle', value: 'Botella' },
        { key: 'jar', value: 'Tarro' },
        { key: 'packet', value: 'Paquete' },
        { key: 'bag', value: 'Bolsa' },

        { key: '150ml', value: '150ml' },
        { key: '250ml', value: '250ml' },
    ],
    linksKv: [
        { key: 'product', value: 'Producto' },
        { key: 'item', value: 'Item de Inventario' },
    ],
    storageTypeKv: [
        { key: 'frozen', value: 'Congelado' },
        { key: 'cold', value: 'Refrigerado' },
    ],
    instanceStatusKv: [
        { key: 0, value: 'Pendiente', badge: 'bg-warning text-dark' },
        { key: 1, value: 'Terminado', badge: 'bg-success' },
    ],
    paymentMethodsKv: [
        { key: 1, value: 'Efectivo' },
        { key: 2, value: 'Tarjeta Débito' },
        { key: 3, value: 'Tarjeta Crédito' },
        { key: 4, value: 'Transferencia' },
        { key: 5, value: 'Cheque' },
        { key: 6, value: 'Rappi' },
        { key: 7, value: 'Otro' },
        { key: 8, value: 'Tarjeta (antiguo)' },
    ],
    storesKv: [
        { key: 1, shortname: 'VAL', name: 'Valparaíso' },
        { key: 2, shortname: 'VDM', name: 'Viña Del Mar' },
    ],
    requestStatusKv: [
        { key: 0, value: 'Pendiente',   class: 'text-warning' },
        { key: 1, value: 'Aprobado',    class: 'text-success' },
        { key: 2, value: 'Rechazado',   class: 'text-danger' },
    ],
    monthNamesKv: [
        { key: 1, value: 'enero' },
        { key: 2, value: 'febrero' },
        { key: 3, value: 'marzo' },
        { key: 4, value: 'abril' },
        { key: 5, value: 'mayo' },
        { key: 6, value: 'junio' },
        { key: 7, value: 'julio' },
        { key: 8, value: 'agosto' },
        { key: 9, value: 'septiembre' },
        { key: 10, value: 'octubre' },
        { key: 11, value: 'noviembre' },
        { key: 12, value: 'diciembre' },
    ],
    docsTypesKv: [
        { key: 'employee_guidelines', value: 'Pautas de Trabajo', visibility: 'public' },
        { key: 'internal_newsletter', value: 'Boletín Interno', visibility: 'public' }, // antes era restricted
        { key: 'salary_settlement', value: 'Liquidación de Sueldo', visibility: 'private' },
    ],
    docsVisibilityKv: [
        { key: 'public', value: 'Público' },
        { key: 'private', value: 'Privado' },
        { key: 'restricted', value: 'Restringido' },
    ],
    employmentStatusKv: [
        { key: 'active', value: 'Activo' },
        { key: 'inactive', value: 'Inactivo' },
        { key: 'terminated', value: 'Desvinculado' },
        { key: 'on-leave', value: 'Licencia' },
        { key: 'suspended', value: 'Suspendido' },
    ],
    pricesKv: [
        { key: 'public', value: 'Público', shorthand: '' , badge: 'bg-primary' },
        { key: 'wholesale', value: 'Mayorista', shorthand: '(myta.)' , badge: 'bg-warning text-dark' },
        { key: 'rappi', value: 'Rappi', shorthand: '(rpp.)' , badge: 'bg-danger' },
        { key: 'offer', value: 'Oferta', shorthand: '(ofta.)' , badge: 'bg-success' },
        { key: 'old', value: 'Antiguo', shorthand: '(antg.)' , badge: 'bg-secondary' },
    ],
}  